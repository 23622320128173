import React from 'react';
import styled from 'styled-components';
import { paths } from './paths';

const Hamburger = ({ setShow, show, path }) => {
    return (
        <HamburgerButton onClick={() => setShow(() => !show)}>
            <HamburgerSpan path={path} show={show}></HamburgerSpan>
            <HamburgerSpan path={path} show={show}></HamburgerSpan>
        </HamburgerButton>
    )
}

const HamburgerButton = styled.button`
    align-items: flex-end;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 0;

    :focus {
        outline: none;
    }
`;

const HamburgerSpan = styled.span`
    background-color: ${
        props => paths.includes(props.path) && !props.show ? 'white' : '#3ea5ce'
    };
    height: 2px;
    margin: 3px 0;
    transition: all 0.25s ease-in;
    transform-origin: 6.5px;

    &:nth-child(1) {
        transform: ${({ show }) => show ? 'rotate(45deg)' : 'rotate(0)'};
        width: ${({ show }) => show ? '25px' : '20px'};
    }

    &:nth-child(2) {
        transform: ${({ show }) => show ? 'rotate(-45deg)' : 'rotate(0)'};
        width: 25px;
    }
`;

export default Hamburger;