import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import useScrollPositionForNavVisibility from "./useScrollPositionForNavVisibility";
import { Container } from "../layoutComponents";
import BuyManualButton from "./BuyManualButton";

import Styled from "./styles";
import Hamburger from "./hamburger";
import Sidedrawer from "./sidedrawer";

const Header = ({ className, path }) => {
  const [show, setShow] = useState(false);
  const currentScrollY = useScrollPositionForNavVisibility(path);

  return (
    <Styled.Header
      path={path}
      scrollY={currentScrollY}
      show={show}
      id="nav"
      className={className}
    >
      <Container>
        <Styled.FlexNav path={path} show={show}>
          <Link to="/" onClick={() => setShow(false)}>
            Darwin Domes
          </Link>
          <Hamburger path={path} show={show} setShow={setShow} />
        </Styled.FlexNav>
      </Container>
      <Sidedrawer show={show} setShow={setShow} />
    </Styled.Header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
