import { useState, useEffect } from 'react';
import { paths } from './paths';

const useScrollPositionForNavVisibility = (path) => {
  const [ currentScrollY, setCurrentScrollY ] = useState(0);
  const [ prevScrollY, setPrevScrollY ] = useState(0);

  useEffect(() => {
    // gets current scroll position via event listener
    const handleScroll = () => {
      setCurrentScrollY(window.scrollY);
    }
    document.addEventListener('scroll', handleScroll)
    
    // compares current scroll position to prev and shows/hides nav depending on whether scrolling up or down
    const navEl = document.getElementById('nav');
    if (!paths.includes(path)) {
      if (currentScrollY > prevScrollY) {
        navEl.style['top'] = '-100px';
      } else if (currentScrollY < prevScrollY) {
        navEl.style['top'] = '0px';
      }

      if (currentScrollY < 10) {
        navEl.style['top'] = '0px';
      }

      setPrevScrollY(currentScrollY);
    } else {
      navEl.style['position'] = 'absolute';
      navEl.style['top'] = '0px';
    }
    return () => {
      document.removeEventListener('scroll', handleScroll);
    }
  }, [currentScrollY, prevScrollY, path])

  return currentScrollY;
}

export default useScrollPositionForNavVisibility;