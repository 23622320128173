import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import useScrollYPosition from './useScrollPositionForNavVisibility';

const Sidedrawer = ({ show, setShow }) => {
    const scrollY = useScrollYPosition();
    useEffect(() => {
        const htmlEl = document.querySelector('html');
        const bodyEl = document.querySelector('body');
        if (show) {
            htmlEl.style['overflow-y'] = 'hidden';
            bodyEl.style['position'] = 'absolute';
        } else {
            htmlEl.style['overflow-y'] = 'scroll';
            bodyEl.style['position'] = 'static';
        }
    }, [show])

    const linkStyles = { color: '#3ea5ce', textAlign: 'center' };
    
    return (
        <StyledMenu show={show} top={`${scrollY}px`}>
            <LinkButton onClick={() => setShow(() => !show)}>
                <Link to="/">
                    <h1 style={linkStyles}>
                        Home
                    </h1>
                </Link>
            </LinkButton>
            <LinkButton onClick={() => setShow(() => !show)}>
                <Link to="/about">
                    <h1 style={linkStyles}>
                        About Us
                    </h1>
                </Link>
            </LinkButton>
            <LinkButton onClick={() => setShow(() => !show)}>
                <Link to="/manual">
                    <h1 style={linkStyles}>
                        The Manual
                    </h1>
                </Link>
            </LinkButton>
            <LinkButton onClick={() => setShow(() => !show)}>
                <Link to="/model">
                    <h1 style={linkStyles}>
                        The Model
                    </h1>
                </Link>
            </LinkButton>
            <LinkButton onClick={() => setShow(() => !show)}>
                <Link to="/gallery">
                    <h1 style={linkStyles}>
                        Gallery
                    </h1>
                </Link>
            </LinkButton>
            <LinkButton onClick={() => setShow(() => !show)}>
                <Link to="/contact">
                    <h1 style={linkStyles}>
                        Contact Us
                    </h1>
                </Link>
            </LinkButton>
        </StyledMenu>
    )
}

const StyledMenu = styled.nav`
    display: flex;
    flex-direction: column;
    background-color: #f4fafc;
    height: 100vh;
    opacity: ${props => props.show ? '1' : '0'};
    padding: 5rem 0;
    position: absolute;
    top: 0;
    transition: all .25s ease-in-out;
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    width: 100vw;
    z-index: -10;
`

const LinkButton = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;

    :focus {
        outline: none;
    }
`;

export default Sidedrawer;