import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";

import Styles from "./styles";

const BuyManualButton = ({ show, path }) => {
  const [previousPath, setPreviousPath] = useState("");

  useEffect(() => {
    if (typeof window !== undefined) {
      const windowPath = window.location.href;
      setPreviousPath(windowPath);
    }
  }, []);

  let stripePromise;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUB_KEY);
    }
    return stripePromise;
  };

  const redirectToCheckout = async (event) => {
    event.preventDefault();
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: process.env.GATSBY_STRIPE_PRODUCT_ID, quantity: 1 }],
      successUrl: "http://darwindomes.com/thankyou",
      cancelUrl: previousPath,
    });
    if (error) {
      console.warn("Error:", error);
    }
  };
  return (
    <Styles.Button
      path={path}
      show={show}
      onClick={redirectToCheckout}
      navPurchaseButton
    >
      <Styles.ButtonText path={path}>BUY THE MANUAL</Styles.ButtonText>
    </Styles.Button>
  );
};

export default BuyManualButton;
