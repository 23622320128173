import styled, { css } from "styled-components";
import { paths } from './paths';

const FlexNav = styled.nav`
    align-items: center;
    height: 100px;
    display: flex;
    justify-content: space-between;

    > a {
        flex: 0 0 70%;
        font-size: 20px;
        font-family: Karla;
        font-weight: 700;
        color: ${
            props => paths.includes(props.path) && !props.show ? 'white' : '#3ea5ce'
        };

        @media (max-width:599px) {
            flex: 0 0 50%;
        }
    }
`

const Header = styled.header`
    background-color: ${props => {
        if(paths.includes(props.path) && !props.show) {
            return 'transparent';
        } else if (props.show) {
            return '#f4fafc';
        } else {
            return 'white';
        }
    }};
    border-bottom: ${props => props.scrollY <= 50 || paths.includes(props.path) ? '' : '1px solid #c8c8c8'};
    height: 100px;
    position: fixed;
    transition: top .25s ease-in-out;
    width: 100%;
    z-index: 10;
`

const ButtonText = styled.h5`
    color: ${props => paths.includes(props.path) ? '#C0CEB2' : '#3ea5ce'};
    margin: .25rem;
    transition: all .25s ease-in-out;
`

const Button = styled.button`
  background-color: transparent;
  border: ${props => paths.includes(props.path) ? '2px solid #C0CEB2' : '2px solid #3ea5ce'};
  border-radius: 4px;
  cursor: pointer;
  opacity: ${props => props.show ? '0' : '1'};
  padding: .5rem;
  transition: all .25s ease-in-out;
  visibility: ${props => props.show ? 'hidden' : 'visible'};

  ${props => props.navPurchaseButton && css`
    @media (max-width:599px) {
        margin-right: 1.5rem;
    }
  `}

  :hover {
    background: #8AA989;
    border: 2px solid #8AA989;
    > h5 {
      color: white;
      transition: color .25s ease-out;
    }
  }
`

export default {
    FlexNav,
    Header,
    Button,
    ButtonText,
}